import React from "react"
import { Grid } from "../../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../../components/general/Section/Section"
import { TypographyAnimation } from "../../../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  BlackGridTheme,
  Color,
  FontFamily,
} from "../../../../../../data/style/variables"
import arrowSvg from "../../../../../../images/SVG/Arrow 8.svg"
import { Bold } from "../../../../../general/Bold/Bold"
import {
  Paragraph,
  TitleH2,
  TitleH3,
  Typography,
  TypographyTypes,
} from "../../../../../general/Typography/Typography"
import {
  ArrowElement,
  ContainerElement,
  WorkflowIndexContainer,
  WorkflowItemContainer,
} from "./styled"

export const About: React.FC = () => {
  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...BlackGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
          noPaddingBottom: true,
        }}
        title={
          <TitleH2 color={Color.White}>
            Voordelen van software in de{" "}
            <Bold background={Color.White}>Cloud</Bold>.
          </TitleH2>
        }
      >
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation>
              <TitleH3 color={Color.White}>
                Data verzamelen.
              </TitleH3>
              <Paragraph color={Color.White}>
                Uw werknemers verzamelen de data door de applicaties te
                gebruiken. Deze data wordt vervolgens op verschillende plekken
                ingelezen en gebruikt.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  01
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={1}>
              <TitleH3 color={Color.White}>
                Centrale Cloudopslag.
              </TitleH3>
              <Paragraph color={Color.White}>
                De data is nu veilig, altijd en overal beschikbaar in de Cloud.
                U heeft natuurlijk de volledige controle over wie welke data
                mag inzien.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  02
                </Typography>
              </WorkflowIndexContainer>
              <ArrowElement src={arrowSvg} />
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
        <Section>
          <WorkflowItemContainer>
            <TypographyAnimation index={2}>
              <TitleH3 color={Color.White}>
                Data analyseren.
              </TitleH3>
              <Paragraph color={Color.White}>
                Nu de data centraal staat opgeslagen kan het overal gebruit
                worden. Bijvoorbeeld in uw rapporten en analyses of in andere
                software pakketten.
              </Paragraph>
              <WorkflowIndexContainer>
                <Typography
                  fontFamily={FontFamily.RobotoMono}
                  titleType={TypographyTypes.span}
                  color={Color.WhiteOpague}
                  fontSize={"2rem"}
                >
                  03
                </Typography>
              </WorkflowIndexContainer>
            </TypographyAnimation>
          </WorkflowItemContainer>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
