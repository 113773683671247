import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../../../components/general/Grid/Grid"
import { Section } from "../../../../../../components/general/Section/Section"
import { RoutePath } from "../../../../../../data/RoutePath"
import { Color, GrayGridTheme } from "../../../../../../data/style/variables"
import {
  CtaArrow,
  Rotations,
  Sizes,
} from "../../../../../general/CtaArrow/CtaArrow"
import {
  Paragraph,
  TitleH3,
} from "../../../../../general/Typography/Typography"
import { TypographyAnimation } from "../../../../../general/TypographyAnimation/TypographyAnimation"
import {
  ContainerElement,
  InfoHolderElement,
  ProductArrowContainerElement,
  ProductContainerElement,
  ProductImageElement,
  ProductInfoContainerElement,
  ProductsContainerElement,
} from "./styled"

export const Examples: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      mxf: file(
        relativePath: { eq: "Korfvis-dakventilatoren-2-1024x662.jpg" }
      ) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      checklist: file(relativePath: { eq: "1.jpg" }) {
        childImageSharp {
          sizes(quality: 100, maxWidth: 1024) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `)

  return (
    <ContainerElement>
      <Grid
        gridElementProps={{
          baseHeight: "auto",
          ...GrayGridTheme,
          detailTextColor: Color.DarkGray,
          spaced: true,
        }}
      >
        <Section sectionElementProps={{ noPaddingTop: true, noPadding: true }}>
          <ProductsContainerElement>
            <Link to={RoutePath.Checklist}>
              <ProductContainerElement>
                <ProductInfoContainerElement textAlignRight>
                  <InfoHolderElement>
                    <TypographyAnimation>
                      <TitleH3 color={Color.Black}>
                        Mobiele checklist app
                      </TitleH3>
                      <Paragraph color={Color.Black}>
                        Productiefouten opsporen met onze checklist app.
                      </Paragraph>
                      <ProductArrowContainerElement textAlignRight>
                        <CtaArrow
                          color={Color.Black}
                          size={Sizes.large}
                          rotation={Rotations.left}
                        />
                      </ProductArrowContainerElement>
                    </TypographyAnimation>
                  </InfoHolderElement>
                </ProductInfoContainerElement>
                <TypographyAnimation>
                  <ProductImageElement
                    src={data.checklist.childImageSharp.sizes.src}
                  />
                </TypographyAnimation>
              </ProductContainerElement>
            </Link>
          </ProductsContainerElement>
        </Section>
      </Grid>
    </ContainerElement>
  )
}
