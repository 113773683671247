import React from "react"
import Layout from "../components/layout"
import { Cloud as CloudComponent } from "../components/pages/services/Cloud/Cloud"
import SEO from "../components/seo"
import { Color, GrayGridTheme } from "../data/style/variables"

const Cloud = () => (
  <Layout navColor={Color.Black} borderColor={GrayGridTheme.lineColor}>
    <SEO
      description="Onze software draait in de Cloud voor de beste beschikbaarheid en snelheid."
      title="Cloud based software"
      lang="nl"
    />
    <CloudComponent />
  </Layout>
)

export default Cloud
