import styled from "styled-components/macro"

export const ContainerElement = styled.div`
  display: flex;
`

export const ProductContainerElement = styled.div`
  display: flex;
`

type ProductImageElementProps = {
  src: string
  firstItem?: boolean
}

export const ProductImageElement = styled.div<ProductImageElementProps>`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 80vh;
  min-height: 700px;
  width: 100%;
  flex-grow: 1;
  margin-top: ${props => (props.firstItem ? "0" : "-150px")};
`

type ProductInfoContainerElementProps = {
  textAlignRight?: boolean
}

export const ProductArrowContainerElement = styled.div<
  ProductInfoContainerElementProps
>`
  position: absolute;
  margin-top: 25px;
  left: ${props => (props.textAlignRight ? "unset" : "-125px")};
  right: ${props => (props.textAlignRight ? "-125px" : "unset")};
  transition: 0.4s;
  z-index: 1;
`

export const ProductInfoContainerElement = styled.div<
  ProductInfoContainerElementProps
>`
  z-index: 1;
  flex-grow: 1;
  padding: 25px;
  align-items: center;
  width: 75%;
  display: flex;
  position: relative;
  justify-content: ${props =>
    props.textAlignRight ? "flex-end" : "flex-start"};
  &:hover ${ProductArrowContainerElement} {
    transform: translateX(
      ${props => (props.textAlignRight ? "-15px" : "15px")}
    );
  }
`

export const ProductsContainerElement = styled.div`
  padding-top: 25px;
`

export const InfoHolderElement = styled.div`
  max-width: 350px;
`
