import React from "react"
import "typeface-roboto-mono"
import { Service } from "../Service/Service"
import { About } from "./sections/About/About"
import { Examples } from "./sections/Examples/Examples"

export const Cloud: React.FC = () => {
  return (
    <Service
      heroProps={{
        title: "Cloud based software",
        technicalDetails: "Online - Cloud - Mobiel - PWA",
        subTitle:
          "Onze software draait in de Cloud voor de beste beschikbaarheid en snelheid.",
        infoTexts: ["Altijd beschikbaar", "Cloud computing", "Veilig en snel"],
      }}
    >
      <About />
      <Examples />
    </Service>
  )
}
